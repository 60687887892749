import { useState, useEffect } from "react";
import "./ContesterImage.css";
import { useNavigate } from "react-router-dom";

const imagesArray = [
  { name: "image1", src: "https://i.ibb.co.com/v4Fqj22/image.jpg" },
  { name: "image2", src: "https://i.ibb.co.com/XkVygqz/by-Shoilee.jpg" },
  { name: "image3", src: "https://i.ibb.co.com/LPq3PBm/Toya-Onqu.jpg" },
  { name: "image4", src: "https://i.ibb.co.com/9qmZ0Wf/Sakib-Al-Imran-Khan.jpg" },
  { name: "image5", src: "https://i.ibb.co.com/k57SCGN/Rikta-Islam.jpg" },
  { name: "image6", src: "https://i.ibb.co.com/HXts0PN/Ratry-Bhowmick.jpg" },
  { name: "image7", src: "https://i.ibb.co.com/KqZDhqZ/Nabeeha-Naba.jpg" },
  { name: "image8", src: "https://i.ibb.co.com/h7cVWYD/Mohammad-Rifatul-Alam-Siam.jpg" },
  { name: "image9", src: "https://i.ibb.co.com/6vR3KM9/Moni-Islam.jpg" },
  { name: "image10", src: "https://i.ibb.co.com/nmz03tT/Miraj-Mahamud-Samrat.jpg" },
  { name: "image11", src: "https://i.ibb.co.com/rM6qF7c/Mannan-Wahid.jpg" },
  { name: "image12", src: "https://i.ibb.co.com/9nxBNFS/Mahbubul-Alam-Saiyed.jpg" },
];

function ContesterImage() {
  const [currentMarks, setCurrentMarks] = useState({});
  const [user, setUser] = useState({ name: "", userID: "" });
  const [submittedMarks, setSubmittedMarks] = useState({});
  const navigate = useNavigate();

  // Fetch user from localStorage
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser); // Set the user data from localStorage
    } else {
      navigate("/login"); // If no user found, navigate to login
    }
  }, [navigate]);

  useEffect(() => {
    // Fetch total marks
    fetch("https://photovote-server.vercel.app/api/marks")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch total marks.");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data, "data");

        // Create a map for the marks where the key is the imageName and the value is marksOutOf10
        const marksMap = data.reduce((acc, mark) => {
          acc[mark.imageName] = mark.marksOutOf10;
          return acc;
        }, {});

        setSubmittedMarks(marksMap); // Store the marks in state
      })
      .catch((error) => console.error("Failed to fetch marks:", error));
  }, []);

  const handleMarksChange = (imageName, value) => {
    const clampedValue = Math.max(0, Math.min(Number(value), 10)); // Clamp marks between 0 and 10
    setCurrentMarks((prevMarks) => ({
      ...prevMarks,
      [imageName]: clampedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      userID: user.userID,
      userName: user.name,
      marks: imagesArray.map((image) => ({
        imageName: image.name,
        imageUrl: image.src,
        marks: currentMarks[image.name] || 0,
      })),
    };

    try {
      const response = await fetch("https://photovote-server.vercel.app/api/marks", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Marks saved:", result);
        navigate("/contesterImage/thanks");
      } else {
        console.error("Failed to submit marks.");
      }
    } catch (error) {
      console.error("Error submitting marks:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <>
      <div className="logoutDiv">
        <button className="logOutBtn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <form onSubmit={handleSubmit} className="ms_des_form mx-auto p-4 sm:p-5">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
          {imagesArray.map((image) => (
            <div key={image.name} className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden p-4">
              <img src={image.src} alt={image.name} className="w-full h-48 object-cover rounded-t-lg" />
              <div className="flex flex-col justify-between p-4">
                {submittedMarks[image.name] ? (
                  <div>
                    <p className="total_marks">Marks given: {submittedMarks[image.name]} / 10</p>
                  </div>
                ) : (
                  0 / 10
                )}
                <div>
                  <input
                    type="number"
                    className="markingInput"
                    min="0"
                    max="10"
                    value={currentMarks[image.name] || ""}
                    onChange={(e) => handleMarksChange(image.name, e.target.value)}
                    placeholder="Rate this image out of 10"
                    required                    
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <button type="submit" className="submit_btn">
          Submit Marks
        </button>
      </form>
    </>
  );
}

export default ContesterImage;
