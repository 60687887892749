import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

function Register() {
  const [name, setName] = useState("");
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      const response = await fetch("https://photovote-server.vercel.app/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, userID, password }),
      });

      const data = await response.json();
      if (!response.ok) {
        setErrorMessage(data.message);
      } else {
        setSuccessMessage("User registered successfully!");
        setErrorMessage("");
        setName("");
        setUserID("");
        setPassword("");

        // Redirect to login or another page
        // navigate("/");
      }
    } catch (error) {
      setErrorMessage("An error occurred while registering. Please try again.");
    }
  };

  return (
    <div>
      <h2 className="bg-red-800 text-center text-2xl p-3">Register</h2>
      <form className="ms_form" onSubmit={(e) => e.preventDefault()}>
        <div>
          <label>Name</label>
          <br />
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>User ID</label>
          <br />
          <input
            type="text"
            placeholder="User ID"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
        </div>
        <div>
          <label>Password</label>
          <br />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <input className="btn" type="button" value="Register" onClick={handleRegister} />
      </form>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
    </div>
  );
}

export default Register;
