import { useState } from "react";
import { useNavigate } from "react-router-dom";
import headerImg from "./assets/images/428344799_400440256263099_4801084723891653390_n.jpg";
import "./App.css";

function App() {
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch("https://photovote-server.vercel.app/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        navigate("/contesterImage");
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage("An error occurred while logging in. Please try again.");
    }
  };

  return (
    <div className="">
      <header className="header">
        <img src={headerImg} alt="header" />
      </header>

      <div className="title">
        <a href="./" className="head_text">
          <b>Photo Of The Century 📸</b>
        </a>
      </div>

      {/* login */}
      <form className="ms_form" onSubmit={(e) => e.preventDefault()}>
        <div>
          <label htmlFor="userID">User ID</label>
          <br />
          <input
            type="text"
            placeholder="User ID"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <br />
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <input
          className="submitBtn"
          type="button"
          value="SUBMIT"
          onClick={handleLogin}
        />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </form>
    </div>
  );
}

export default App;
