import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ContesterImage from './pages/ContesterImage';
import PrivateRoute from './pages/PrivateRoute';
import Register from './pages/Register';
import Thanks from './pages/Thanks';


const router = createBrowserRouter([
  {
    path: "/",  // Login page (App.js)
    element: <App />,
  },
  {
    path: '/contesterImage',
    element: <PrivateRoute />,  // Protect this route
    children: [
      { path: '', element: <ContesterImage /> }, 
      { path: 'thanks', element: <Thanks /> }, 
    ],
  },
  {
    path: "/register", // Registration route
    element: <Register />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
