import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const token = localStorage.getItem('token'); // Check for the presence of a token

  // If there is no token, redirect to the login page
  if (!token) {
    return <Navigate to="/" replace />;
  }

  // If the user is authenticated, render the protected route
  return <Outlet />;
};

export default PrivateRoute;
